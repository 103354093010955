<template>
  <div class="skypes">
    <h1>{{ block.item.name }}</h1>

    <div class="btn-block">
      <div class="btn-block-flex">
        <a class="btn" v-for="b in block.confs" :key="'bb-' + b.id" :href="b.url" target="_blank">{{ b.name }}</a>
      </div>
    </div>

    <router-link class="back" :to="{name:'skypes'}">Вернуться</router-link>
  </div>
</template>

<script>
  import {mapState} from "vuex";

  export default {
    name: "SkypesDay",
    created() {
      document.title = "Конференции по группам";
      this.fetch()
    },
    computed: {
      ...mapState({
        block: state => state.skypeBlock
      })
    },
    methods: {
      fetch() {
        this.$store.dispatch('getSkypeBlock', {id: this.$route.params.id});

      },
    },

  }
</script>

<style scoped lang="less">
  @import "../assets/vars";

  .skypes {
    .btn {
      display: block;
      background-color: @color-h;
      color: @color-l;
      text-align: center;
      text-decoration: none;
      font-size: 1.5rem;

      &.btn-big {
        padding: 1rem;
        margin-bottom: 1rem;
        font-size: 2rem;
      }
    }

    .btn-block {
      margin-bottom: 2rem;

      .btn-block-flex {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

        .btn {
          width: 12rem;
          height: 4rem;
          line-height: 4rem;
          margin: .5rem;
        }
      }
    }

    .back {
      text-align: right;
      color: @color-h;
      display: block;
      text-decoration: none;
    }
  }
</style>
